@import './../assets/variables.scss';

.publicLayout {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $white1;

    &__main {
        width: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        row-gap: 8rem;
        padding-top: 6.6rem;
        padding-bottom: 8rem;
        background-color: $white;

        @media screen and (max-width: 768px) {
            padding-top: 5.8rem;
            padding-bottom: 6rem;
            row-gap: 6rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 3rem;
        }
    }
}

.form {
    top: 0 !important;

    &__wrapper {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2.4rem;

        &::before, &::after {
            content: "";
            display: block;
            flex-grow: 1;
        }
    }

    &__bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: $black2, $alpha: 0.5);
    }

    &__container {
        width: 100%;
        max-width: 79rem;
        flex-shrink: 0;
        padding: 2.4rem;
        background-color: $white;
        display: flex;
        flex-direction: column;
        row-gap: 4rem;
        position: relative;
        z-index: 1;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 1.2rem;
    }

    &__closeIco {
        display: block;
        width: 100%;
        height: 100%;
        flex-shrink: 0;

        path {
            stroke: $black;
        }
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        flex-shrink: 0;
        border: none;
        background-color: transparent;

        @media screen and (hover) {
            cursor: pointer;

            .form__closeIco path {
                transition: stroke 0.5s;
            }

            &:hover {
                .form__closeIco path {
                    stroke: $red;
                }
            }
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 4rem;
    }

    &__submit {
        width: 100%;
        background-color: $red;
        color: $white;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s, color 0.5s;
    
            &:hover {
                background-color: $white;
                color: $red;
            }
        }
    }

    &__dnone {
        display: none;
    }

    &__filesLine {
        max-width: 100%;
        width: fit-content;
        display: flex;
        align-items: center;
        column-gap: 1rem;
        padding-right: 1rem;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            &:hover {
                background-color: rgba($color: $gray5, $alpha: 0.7);

                .form__fileIco {
                    border-color: transparent;
                }
            }
        }
    }

    &__fileIco {
        display: block;
        width: 4rem;
        height: 4rem;
        flex-shrink: 0;
        border: 1px solid rgba($color: $black2, $alpha: 0.1);
    }

    &__files {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
    }

    &__file {
        color: $black2;
        font-size: 1.1rem;
        line-height: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.04em;
        text-transform: uppercase;
    }

    &__fileDelete {
        flex-shrink: 0;
        background-color: transparent;
        border: none;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
    }

    &__fileDeleteIco {
        flex-shrink: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}