@import './../../assets/variables.scss';

.portal {
    position: fixed;
    z-index: 10;
    inset: 0;
    top: 6.6rem;
    display: flex;
    flex-direction: column;
    background-color: rgba($color: $black, $alpha: 0.25);
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;

    @media screen and (max-width: 768px) {
        top: 5.8rem;
    }

    &_show {
        opacity: 1;
        pointer-events: all;
    }
}