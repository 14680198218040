@import './../../../assets/variables.scss';

.link {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    justify-content: space-between;

    @media screen and (hover) {
        cursor: pointer;
        transition: background-color 0.5s;

        .link__title::after {
            content: attr(data-text);
            color: $red;
            position: absolute;
            left: 0;
            pointer-events: none;
            width: 0;
            overflow: hidden;
            transition: width 0.5s;
            white-space: nowrap;
        }

        .link__ico {
            transition:
                stroke 0.5s,
                fill 0.5s;
        }

        &:hover {
            .link__title::after {
                width: 100%;
            }

            .link__ico {
                fill: $red;

                &_stroke {
                    stroke: $red;
                    fill: none;
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        justify-content: center;
    }

    &__title {
        color: $black;
        font-size: 1rem;
        line-height: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.04em;
        position: relative;
        white-space: nowrap;
        text-transform: uppercase;
    }

    &__menuLink {
        @media screen and (max-width: 768px) {
            text-transform: uppercase;
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 500;
            letter-spacing: 0;
        }
    }

    &__whiteLink {
        color: $white1 !important;
        font-size: 1.4rem;
        line-height: 1.9rem;
        font-weight: 300;

        @media screen and (max-width: 400px) {
            font-size: 1.2rem;
            line-height: 1.6rem;
        }
    }

    &__ico {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        fill: $black;
        flex-shrink: 0;

        @media screen and (max-width: 1200px) {
            width: 2rem;
            height: 2rem;
        }

        &_stroke {
            stroke: $black;
            fill: none;
        }
    }

    &_active {
        pointer-events: none;

        .link__title {
            color: $red;
        }

        .link__ico {
            fill: $red;

            &_stroke {
                stroke: $red;
                fill: none;
            }
        }
    }
}
