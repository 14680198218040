// @font-face {
//     font-family: 'AkzidenzGrotesk';
//     src: url('AkzidenzGroteskPro-Bold.eot');
//     src: local('Akzidenz-Grotesk Pro Bold'), local('AkzidenzGroteskPro-Bold'),
//         url('AkzidenzGroteskPro-Bold.eot?#iefix') format('embedded-opentype'),
//         url('AkzidenzGroteskPro-Bold.woff2') format('woff2'),
//         url('AkzidenzGroteskPro-Bold.woff') format('woff'),
//         url('AkzidenzGroteskPro-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'AkzidenzGrotesk';
//     src: url('AkzidenzGroteskPro-Light.eot');
//     src: local('Akzidenz-Grotesk Pro Light'), local('AkzidenzGroteskPro-Light'),
//         url('AkzidenzGroteskPro-Light.eot?#iefix') format('embedded-opentype'),
//         url('AkzidenzGroteskPro-Light.woff2') format('woff2'),
//         url('AkzidenzGroteskPro-Light.woff') format('woff'),
//         url('AkzidenzGroteskPro-Light.ttf') format('truetype');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'AkzidenzGrotesk';
//     src: url('AkzidenzGroteskPro-It.eot');
//     src: local('Akzidenz-Grotesk Pro Italic'), local('AkzidenzGroteskPro-It'),
//         url('AkzidenzGroteskPro-It.eot?#iefix') format('embedded-opentype'),
//         url('AkzidenzGroteskPro-It.woff2') format('woff2'),
//         url('AkzidenzGroteskPro-It.woff') format('woff'),
//         url('AkzidenzGroteskPro-It.ttf') format('truetype');
//     font-weight: normal;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'AkzidenzGrotesk';
//     src: url('AkzidenzGroteskPro-Md.eot');
//     src: local('Akzidenz-Grotesk Pro Med'), local('AkzidenzGroteskPro-Md'),
//         url('AkzidenzGroteskPro-Md.eot?#iefix') format('embedded-opentype'),
//         url('AkzidenzGroteskPro-Md.woff2') format('woff2'),
//         url('AkzidenzGroteskPro-Md.woff') format('woff'),
//         url('AkzidenzGroteskPro-Md.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'AkzidenzGrotesk';
//     src: url('AkzidenzGroteskPro-MdIt.eot');
//     src: local('Akzidenz-Grotesk Pro MedItal'), local('AkzidenzGroteskPro-MdIt'),
//         url('AkzidenzGroteskPro-MdIt.eot?#iefix') format('embedded-opentype'),
//         url('AkzidenzGroteskPro-MdIt.woff2') format('woff2'),
//         url('AkzidenzGroteskPro-MdIt.woff') format('woff'),
//         url('AkzidenzGroteskPro-MdIt.ttf') format('truetype');
//     font-weight: 500;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'AkzidenzGrotesk';
//     src: url('AkzidenzGroteskPro-XBdIt.eot');
//     src: local('Akzidenz-Grotesk Pro ExtraBldIt'), local('AkzidenzGroteskPro-XBdIt'),
//         url('AkzidenzGroteskPro-XBdIt.eot?#iefix') format('embedded-opentype'),
//         url('AkzidenzGroteskPro-XBdIt.woff2') format('woff2'),
//         url('AkzidenzGroteskPro-XBdIt.woff') format('woff'),
//         url('AkzidenzGroteskPro-XBdIt.ttf') format('truetype');
//     font-weight: 800;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'AkzidenzGrotesk';
//     src: url('AkzidenzGroteskPro-BoldIt.eot');
//     src: local('Akzidenz-Grotesk Pro BoldIt'), local('AkzidenzGroteskPro-BoldIt'),
//         url('AkzidenzGroteskPro-BoldIt.eot?#iefix') format('embedded-opentype'),
//         url('AkzidenzGroteskPro-BoldIt.woff2') format('woff2'),
//         url('AkzidenzGroteskPro-BoldIt.woff') format('woff'),
//         url('AkzidenzGroteskPro-BoldIt.ttf') format('truetype');
//     font-weight: bold;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'AkzidenzGrotesk';
//     src: url('AkzidenzGroteskPro-XBd.eot');
//     src: local('Akzidenz-Grotesk Pro ExtraBold'), local('AkzidenzGroteskPro-XBd'),
//         url('AkzidenzGroteskPro-XBd.eot?#iefix') format('embedded-opentype'),
//         url('AkzidenzGroteskPro-XBd.woff2') format('woff2'),
//         url('AkzidenzGroteskPro-XBd.woff') format('woff'),
//         url('AkzidenzGroteskPro-XBd.ttf') format('truetype');
//     font-weight: 800;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'AkzidenzGrotesk';
//     src: url('AkzidenzGroteskPro-Regular.eot');
//     src: local('Akzidenz-Grotesk Pro Regular'), local('AkzidenzGroteskPro-Regular'),
//         url('AkzidenzGroteskPro-Regular.eot?#iefix') format('embedded-opentype'),
//         url('AkzidenzGroteskPro-Regular.woff2') format('woff2'),
//         url('AkzidenzGroteskPro-Regular.woff') format('woff'),
//         url('AkzidenzGroteskPro-Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'AkzidenzGrotesk';
//     src: url('AkzidenzGroteskPro-LightIt.eot');
//     src: local('Akzidenz-Grotesk Pro LightIt'), local('AkzidenzGroteskPro-LightIt'),
//         url('AkzidenzGroteskPro-LightIt.eot?#iefix') format('embedded-opentype'),
//         url('AkzidenzGroteskPro-LightIt.woff2') format('woff2'),
//         url('AkzidenzGroteskPro-LightIt.woff') format('woff'),
//         url('AkzidenzGroteskPro-LightIt.ttf') format('truetype');
//     font-weight: 300;
//     font-style: italic;
// }


@font-face {
    font-family: 'AkzidenzGrotesk';
    src: url("./AkzidenzGroteskPro/akzidenzgroteskpro_lightex.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 300; 
}

@font-face {
    font-family: 'AkzidenzGrotesk';
    src: url("./AkzidenzGroteskPro/akzidenzgroteskpro_ext.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 400; 
}

@font-face {
    font-family: 'AkzidenzGrotesk';
    src: url("./AkzidenzGroteskPro/akzidenzgroteskpro_mdex.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 500; 
}

@font-face {
    font-family: 'AkzidenzGrotesk';
    src: url('AkzidenzGroteskPro-XBd.eot');
    src: local('Akzidenz-Grotesk Pro ExtraBold'), local('AkzidenzGroteskPro-XBd'),
        url('AkzidenzGroteskPro-XBd.eot?#iefix') format('embedded-opentype'),
        url('AkzidenzGroteskPro-XBd.woff2') format('woff2'),
        url('AkzidenzGroteskPro-XBd.woff') format('woff'),
        url('AkzidenzGroteskPro-XBd.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'AkzidenzGrotesk';
    src: url("./AkzidenzGroteskPro/akzidenzgroteskpro_super.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 900; 
}