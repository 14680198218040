@import './../../../assets/variables.scss';

.h3 {
    color: $black;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    @media screen and (max-width: 400px) {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}