@import "./../../assets/variables.scss";

.page404 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2.4rem;
    flex-grow: 1;

    &::before, &::after {
        content: "";
        display: block;
        flex-grow: 1;
    }

    &__header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1.2rem;
    }

    &__title {
        color: $red;
        font-size: 19rem;
        line-height: 23rem;
        font-weight: 500;
        text-align: center;

        @media screen and (max-width: 1500px) {
            font-size: 17rem;
            line-height: 21rem;
        }

        @media screen and (max-width: 1200px) {
            font-size: 15rem;
            line-height: 19rem;
        }

        @media screen and (max-width: 650px) {
            font-size: 10rem;
            line-height: 13rem;
        }

        @media screen and (max-width: 510px) {
            font-size: 8rem;
            line-height: 11rem;
        }
    }

    &__error {
        color: $red;
        font-size: 4.2rem;
        line-height: 5rem;
        font-weight: 700;
        text-align: center;

        @media screen and (max-width: 1200px) {
            font-size: 3rem;
            line-height: 4rem;
        }

        @media screen and (max-width: 650px) {
            font-size: 2.4rem;
            line-height: 3.4rem;
        }
    }

    &__footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 5.2rem;

        @media screen and (max-width: 650px) {
            row-gap: 3.2rem;
        }
    }

    &__texts {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1.2rem;
    }

    &__description {
        color: $black;
        font-size: 3.2rem;
        line-height: 3.8rem;
        font-weight: 400;
        text-align: center;

        @media screen and (max-width: 1200px) {
            font-size: 2.4rem;
            line-height: 3.4rem;
        }

        @media screen and (max-width: 650px) {
            font-size: 1.6rem;
            line-height: 2.6rem;
        }
    }

    &__propose {
        color: rgba($color: $black, $alpha: 0.6);
        font-size: 3.2rem;
        line-height: 3.8rem;
        font-weight: 400;
        text-align: center;

        @media screen and (max-width: 1200px) {
            font-size: 2.4rem;
            line-height: 3.4rem;
        }

        @media screen and (max-width: 650px) {
            font-size: 1.6rem;
            line-height: 2.6rem;
        }
    }

    &__button {
        min-height: 6.1rem;
        min-width: 44.5rem;
        font-size: 2.4rem;
        line-height: 2.9rem;
        font-weight: 500;

        @media screen and (max-width: 650px) {
            min-height: 5.2rem;
            min-width: 30rem;
            font-size: 1.8rem;
            line-height: 2rem;
            font-weight: 600;
        }

        @media screen and (max-width: 510px) {
            min-width: 0;
            width: 100%;
            font-size: 1.4rem;
            line-height: 1.6rem;
        }
    }
}