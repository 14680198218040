@import './../../assets/variables.scss';

.logo {
    display: block;
    flex-shrink: 0;
    width: 8rem;
    height: 3.4rem;

    path {
        fill: $red;
    }

    @media screen and (hover) {
        cursor: pointer;

        path {
            transition: fill 0.5s;
        }

        &:hover path {
            fill: $black1;
        }
    }

    @media screen and (max-width: 768px) {
        width: 6.2rem;
        height: 2.6rem;
    }
}