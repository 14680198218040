@import './../../../assets/variables.scss';

.button {
    min-height: 4.2rem;
    width: fit-content;
    max-width: 100%;
    padding: 0.5rem 2.8rem;
    border: 1px solid $red;
    background-color: transparent;
    border-radius: 0.2rem;
    color: $red;
    font-size: 1.1rem;
    line-height: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;

    @media screen and (hover) {
        cursor: pointer;
        transition: background-color 0.5s, color 0.5s;

        .button__ico path {
            transition: fill 0.5s;
        }

        &:hover {
            background-color: $red;
            color: $white;

            .button__ico path {
                fill: $white;
            }
        }
    }

    &__ico {
        display: block;
        flex-shrink: 0;
        width: 1.4rem;
        height: 1.4rem;

        path {
            fill: $red;
        }
    }
}