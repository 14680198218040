@import './../../assets/variables.scss';

.cookie {
    width: 40.8rem;
    max-width: calc(100% - 6.4rem);
    position: fixed;
    z-index: 10;
    background-color: $black;
    top: 40%;
    right: 3.2rem;
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
    padding: 2.4rem;
    border-radius: 0.8rem;

    @media screen and (max-width: 850px) {
        row-gap: 2rem;
        padding: 1.6rem;
        max-width: calc(100% - 3.2rem);
        right: 1.6rem;
    }

    &__textBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &__title {
        color: $white;
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: 400;

        @media screen and (max-width: 850px) {
            font-size: 1.6rem;
            line-height: 1.8rem;
        }
    }

    &__text{
        color: $white1;
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: 400;

        @media screen and (max-width: 850px) {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }

        a {
            color: $white;
            text-decoration: underline;
            text-underline-offset: 3px;

            @media screen and (hover) {
                cursor: pointer;
                transition: color 0.5s;

                &:hover {
                    color: $red;
                }
            }
        }
    }

    &__button {
        min-height: 4.2rem;
        width: 100%;
        padding: 0.5rem;
        border: 1px solid  $red;
        background-color:  $red;
        border-radius: 0.8rem;
        color: $white1;
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: 500;
    
        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s, color 0.5s;
    
            &:hover {
                background-color: transparent;
                color: $red;
            }
        }

        @media screen and (max-width: 850px) {
            font-size: 1.4rem;
            line-height: 1.6rem;
            min-height: 3.6rem;
        }
    }
}