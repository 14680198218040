@import './../../assets/variables.scss';

.worth {
    width: 100%;
    min-width: 0;
    background-color: $red;
    border-radius: 0.2rem;
    padding: 2.4rem;
    filter: drop-shadow(0 3.8rem 3.8rem rgba($black1, 0.03));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 2.4rem;
    min-height: 23.6rem;
    h3,
    p {
        color: $white !important;
    }

    @media screen and (max-width: 768px) {
        min-height: 18rem;
        filter: drop-shadow(0 1.1rem 1.1rem rgba($black1, 0.03));
    }
}
