@import './../../assets/variables.scss';

.footer {
    width: 100%;
    padding: 4rem 0 2rem;
    display: flex;
    justify-content: center;
    background-color: $black;

    @media screen and (max-width: 768px) {
        min-height: 5.8rem;
        padding: 6rem 0 6rem;
    }

    &__container {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
    }

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        column-gap: 2.4rem;

        @media screen and (max-width: 450px) {
            flex-direction: column;
            row-gap: 6rem;
        }
    }

    &__contacts {
        display: flex;
        column-gap: 2.4rem;

        @media screen and (max-width: 450px) {
            justify-content: space-between;
        }
    }

    &__contactsData {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;

        @media screen and (max-width: 768px) {
            row-gap: 0.8rem;
        }

        &_toTouch {
            justify-content: space-between;
        }
    }

    &__city {
        width: 100%;
        display: flex;
        position: relative;
        white-space: nowrap;
        font-family: $roboto-family;
        color: $white1;
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 300;
        text-decoration: underline;
        position: relative;
    
        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;
    
            &::after {
                content: attr(data-text);
                color: $red;
                position: absolute;
                left: 0;
                pointer-events: none;
                width: 0;
                overflow: hidden;
                transition: width 0.5s;
                white-space: nowrap;
            }
    
            &:hover {
                &::after {
                    width: 100%;
                }
            }
        }
    }

    &__privacy {
        font-family: $roboto-family;
        color: rgba($color: $white, $alpha: 0.4);
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 400;
    }
}