@import './../../assets/variables.scss';

.input {
    min-height: 3.4rem;
    width: 100%;
    border-bottom: 1px solid $gray1;
    padding-bottom: 0.8rem;
    font-family: $roboto-family;
    color: $black2;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;

    &_required {
        padding-right: 1rem;
    }

    &::placeholder {
        color: rgba($color: $black2, $alpha: 0.3);
    }

    &__wrapper {
        width: 100%;
        position: relative;
    }

    .phoneInput.form-control {
        align-self: center;
        position: relative;
        letter-spacing: inherit;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-left: 5rem;
        margin-left: 0;
        background: transparent;
        border: none;
        border-radius: 0;
        height: 100%;
        width: 0;
        flex-grow: 1;
        outline: none;
        font-family: $roboto-family;
        color: $black2;
        font-size: 1.6rem;
        line-height: 2.5rem;
        font-weight: 400;

        &::placeholder {
            color: rgba($color: $black2, $alpha: 0.3);
        }

        &.disabled {
            cursor: auto;
        }
    }

    .buttonClass.selected-flag {
        background-color: transparent !important;
        border: none !important;
        padding-bottom: 0.8rem;
    }

    .dropDown {
        max-height: 10rem !important;
    }

    &.little {
        min-height: 4.1rem;
        padding: 0.5rem 0.8rem;

        .phoneInput.form-control {
            font-size: 1.4rem;
            line-height: 1.7rem;
        }
    }

    &_error {
        border-color: $red;
    }

    &.disabled {
        border-color: transparent;
        background-color: transparent;
    }

    &__required {
        position: absolute;
        right: 0;
        top: calc(50% - 0.8rem);
        font-family: $roboto-family;
        color: $red;
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: 400;
        pointer-events: none;
    }
}
