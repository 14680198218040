@import './../../../assets/variables.scss';

.h2 {
    color: $black2;
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 400;
    text-transform: uppercase;

    @media screen and (max-width: 1200px) {
        font-size: 2.8rem;
        line-height: 3.1rem;
    }

    @media screen and (max-width: 900px) {
        font-size: 2.4rem;
        line-height: 2.8rem;
    }

    @media screen and (max-width: 400px) {
        font-size: 2rem;
        line-height: 2.4rem;
    }
}