@import './../../../assets/variables.scss';

.text {
    font-family: $roboto-family;
    color: $black2;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;

    @media screen and (max-width: 768px) {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }

    @media screen and (max-width: 370px) {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }

    &.gray {
        color: $gray;
    }

    &.red {
        color: $red;
    }
}