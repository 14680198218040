@import './../../assets/variables.scss';

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 6.6rem;
    background-color: $white1;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: 900px) {
        min-height: 5.8rem;
    }

    &__container {
        width: 100%;
        display: grid;
        grid-template-columns: 17.6rem 1fr;
        align-items: center;
        column-gap: 2.4rem;

        @media screen and (max-width: 1050px) {
            grid-template-columns: auto 1fr;
        }

        @media screen and (max-width: 900px) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__logo {
        display: flex;
    }

    &__menu {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        column-gap: 2.4rem;

        @media screen and (max-width: 900px) {
            align-self: flex-end;
            height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 5rem 2rem 2rem;
            row-gap: 4rem;
            width: 36rem;
            max-width: 100%;
            background-color: $white1;
        }

        @media screen and (max-width: 450px) {
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    &__nav {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-end;
        align-items: center;
        column-gap: 3.1rem;

        @media screen and (max-width: 900px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 3.2rem;
        }

        @media screen and (max-width: 450px) {
            justify-content: center;
            column-gap: 3.6rem;
        }
    }

    &__join {
        min-height: 2.8rem;
        font-size: 1.2rem;
        line-height: 1.4rem;

        @media screen and (max-width: 900px) {
            min-height: 3.4rem;
            font-size: 1.4rem;
            line-height: 1.6rem;
            width: 100%;
        }

        @media screen and (max-width: 450px) {
            margin-top: 4rem;
            background-color: $red;
            color: $white;

            .button__ico path {
                fill: $white;
            }
        }
    }

    &__progress {
        width: 100%;
        height: 0.4rem;
        display: flex;
        background-color: $gray4;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    &__progressBar {
        height: 100%;
        background-color: $red;
        transition: width 0.3s;
    }
}

.burger {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem 0.2rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: none;

    @media screen and (hover) {
        cursor: pointer;

        &:hover .burger__line {
            background-color: $red;
        }
    }

    @media screen and (max-width: 900px) {
        display: flex;
    }

    &__line {
        width: 2rem;
        height: 1px;
        background-color: $black1;
        transition: transform 0.5s, background-color 0.5s;
    }

    &.active {
        .burger__line:nth-child(2) {
            display: none;
        }

        .burger__line:nth-child(1) {
            transform: translateY(0.7rem) rotateZ(45deg);
        }

        .burger__line:nth-child(3) {
            transform: translateY(-0.7rem) rotateZ(-45deg);
        }
    }
    
}