@import './../../assets/variables.scss';

.indicator {
    width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1.4rem;
    align-items: center;
    text-align: center;

    &__count {
        color: $red;
        font-size: 8rem;
        line-height: 10.2rem;
        font-weight: 400;

        @media screen and (max-width: 1050px) {
            font-size: 6rem;
            line-height: 7.6rem;
        }

        &.black {
            color: $white1;
        }
    }
}