@import './../../assets/variables.scss';

.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    background-color: $gray1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__spinner {
        font-size: 1rem;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        text-indent: -9999em;
        animation: mulShdSpin 1.1s infinite ease;
        transform: translateZ(0);
    }

    @keyframes mulShdSpin {
        0%,
        100% {
            box-shadow:
                0em -2.6em 0em 0em #9C1F2E,
                1.8em -1.8em 0 0em rgba(156, 31, 46, 0.2),
                2.5em 0em 0 0em rgba(156, 31, 46, 0.2),
                1.75em 1.75em 0 0em rgba(156, 31, 46, 0.2),
                0em 2.5em 0 0em rgba(156, 31, 46, 0.2),
                -1.8em 1.8em 0 0em rgba(156, 31, 46, 0.2),
                -2.6em 0em 0 0em rgba(156, 31, 46, 0.5),
                -1.8em -1.8em 0 0em rgba(156, 31, 46, 0.7);
        }
        12.5% {
            box-shadow:
                0em -2.6em 0em 0em rgba(156, 31, 46, 0.7),
                1.8em -1.8em 0 0em #9C1F2E,
                2.5em 0em 0 0em rgba(156, 31, 46, 0.2),
                1.75em 1.75em 0 0em rgba(156, 31, 46, 0.2),
                0em 2.5em 0 0em rgba(156, 31, 46, 0.2),
                -1.8em 1.8em 0 0em rgba(156, 31, 46, 0.2),
                -2.6em 0em 0 0em rgba(156, 31, 46, 0.2),
                -1.8em -1.8em 0 0em rgba(156, 31, 46, 0.5);
        }
        25% {
            box-shadow:
                0em -2.6em 0em 0em rgba(156, 31, 46, 0.5),
                1.8em -1.8em 0 0em rgba(156, 31, 46, 0.7),
                2.5em 0em 0 0em #9C1F2E,
                1.75em 1.75em 0 0em rgba(156, 31, 46, 0.2),
                0em 2.5em 0 0em rgba(156, 31, 46, 0.2),
                -1.8em 1.8em 0 0em rgba(156, 31, 46, 0.2),
                -2.6em 0em 0 0em rgba(156, 31, 46, 0.2),
                -1.8em -1.8em 0 0em rgba(156, 31, 46, 0.2);
        }
        37.5% {
            box-shadow:
                0em -2.6em 0em 0em rgba(156, 31, 46, 0.2),
                1.8em -1.8em 0 0em rgba(156, 31, 46, 0.5),
                2.5em 0em 0 0em rgba(156, 31, 46, 0.7),
                1.75em 1.75em 0 0em #9C1F2E,
                0em 2.5em 0 0em rgba(156, 31, 46, 0.2),
                -1.8em 1.8em 0 0em rgba(156, 31, 46, 0.2),
                -2.6em 0em 0 0em rgba(156, 31, 46, 0.2),
                -1.8em -1.8em 0 0em rgba(156, 31, 46, 0.2);
        }
        50% {
            box-shadow:
                0em -2.6em 0em 0em rgba(156, 31, 46, 0.2),
                1.8em -1.8em 0 0em rgba(156, 31, 46, 0.2),
                2.5em 0em 0 0em rgba(156, 31, 46, 0.5),
                1.75em 1.75em 0 0em rgba(156, 31, 46, 0.7),
                0em 2.5em 0 0em #9C1F2E,
                -1.8em 1.8em 0 0em rgba(156, 31, 46, 0.2),
                -2.6em 0em 0 0em rgba(156, 31, 46, 0.2),
                -1.8em -1.8em 0 0em rgba(156, 31, 46, 0.2);
        }
        62.5% {
            box-shadow:
                0em -2.6em 0em 0em rgba(156, 31, 46, 0.2),
                1.8em -1.8em 0 0em rgba(156, 31, 46, 0.2),
                2.5em 0em 0 0em rgba(156, 31, 46, 0.2),
                1.75em 1.75em 0 0em rgba(156, 31, 46, 0.5),
                0em 2.5em 0 0em rgba(156, 31, 46, 0.7),
                -1.8em 1.8em 0 0em #9C1F2E,
                -2.6em 0em 0 0em rgba(156, 31, 46, 0.2),
                -1.8em -1.8em 0 0em rgba(156, 31, 46, 0.2);
        }
        75% {
            box-shadow:
                0em -2.6em 0em 0em rgba(156, 31, 46, 0.2),
                1.8em -1.8em 0 0em rgba(156, 31, 46, 0.2),
                2.5em 0em 0 0em rgba(156, 31, 46, 0.2),
                1.75em 1.75em 0 0em rgba(156, 31, 46, 0.2),
                0em 2.5em 0 0em rgba(156, 31, 46, 0.5),
                -1.8em 1.8em 0 0em rgba(156, 31, 46, 0.7),
                -2.6em 0em 0 0em #9C1F2E,
                -1.8em -1.8em 0 0em rgba(156, 31, 46, 0.2);
        }
        87.5% {
            box-shadow:
                0em -2.6em 0em 0em rgba(156, 31, 46, 0.2),
                1.8em -1.8em 0 0em rgba(156, 31, 46, 0.2),
                2.5em 0em 0 0em rgba(156, 31, 46, 0.2),
                1.75em 1.75em 0 0em rgba(156, 31, 46, 0.2),
                0em 2.5em 0 0em rgba(156, 31, 46, 0.2),
                -1.8em 1.8em 0 0em rgba(156, 31, 46, 0.5),
                -2.6em 0em 0 0em rgba(156, 31, 46, 0.7),
                -1.8em -1.8em 0 0em #9C1F2E;
        }
    }
}
