@import './../../assets/variables.scss';

.advantage {
    width: 100%;
    display: flex;

    &_reverse {
        flex-direction: row-reverse;

        @media screen and (max-width: 768px) {
            text-align: left;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    &__pictureWrapper {
        width: 43.5%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__picture {
        display: block;
        width: 100%;
        filter: drop-shadow(0 1.1rem 1.1rem rgba($black1, 0.07));
        cursor: pointer;

        @media screen and (max-width: 1050px) {
            height: 100%;
            object-fit: cover;
            object-position: center bottom;
        }

        @media screen and (max-width: 768px) {
            height: auto;
            object-fit: unset;
        }

        @media screen and (hover) {
            transition: transform 0.5s;

            &:hover {
                transform: scale(1.3);
            }
        }
    }

    &__content {
        width: 56.5% ;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 1.6rem;
        background-color: $gray4;
        transition: background-color 0.5s;
        padding: 2.4rem;

        @media screen and (hover) {
            &:hover {
                background-color: $sand;
            }
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 1.6rem;
        }

        &.sand {
            background-color: $sand;
        }
    }

    &__points {
        width: 100%;
        flex-direction: column;
        row-gap: 1.6rem;
        display: none;
        opacity: 0;
        transition: opacity 0.5s;

        &.show {
            display: flex;
            opacity: 1;
        }
    }

    &__text {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 1.6rem;

        & p {
            color: $black2 !important;
        }

        &::before {
            content: "";
            width: 0.8rem;
            height: 0.8rem;
            flex-shrink: 0;
            display: block;
            background-color: $red;
        }
    }

    &__buttonText {
        transition: color 0.5s;
        color: $red;
        font-size: 1.1rem;
        line-height: 1.4rem;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.04em;
    }

    &__buttonIco {
        display: block;
        flex-shrink: 0;
        width: 1.6rem;
        height: 1.6rem;

        path  {
            fill: $red;
            transition: fill 0.5s;
        }
    }

    &__button {
        padding: 1rem 2rem;
        align-self: flex-start;
        display: flex;
        align-items: center;
        column-gap: 1rem;
        border: 1px solid $red;
        background-color: transparent;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            &:hover {
                background-color: $red;

                .advantage__buttonText {
                    color: $sand;
                }
    
                .advantage__buttonIco path {
                    fill: $sand;
                }
            }
        }

        @media screen and (max-width: 450px) {
            align-self: stretch;
            justify-content: center;
        }

        &.red {
            background-color: $red;

            .advantage__buttonText {
                color: $sand;
            }

            .advantage__buttonIco path {
                fill: $sand;
            }
        }
    }
}