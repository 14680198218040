@import "./fonts/Akzidenz-Grotesk-Pro/stylesheet.scss";
@import "./fonts/Roboto-Flex/stylesheet.scss";
/*Fonts*/
$grotesk-family: AkzidenzGrotesk, sans-serif;
$roboto-family: RobotoFlex, sans-serif;
/*END Global*/

/*Colors*/
$white: #ffffff;
$white1: #F7F7F7;
$white2: #f6f6f6;
$red: #9C1F2E;
$black: #323232;
$black1: #000000;
$black2: #170D0F;
$gray: #7D7D7D;
$gray1: #D0D0D0;
$gray2: #DBDBDB;
$gray3: #E8E8E8;
$gray4: #EDEDED;
$gray5: #EDEDEDB2;
$green: #1f9c34;
$sand: #EDE3D3;
/*END Colors*/